<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Periode</label>
                    <Calendar selectionMode="range"  v-tooltip.top="'Maksimal 30 hari'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="visit_type">Visit Type</label>
                    <Dropdown v-model="filters.visit_type" :options="dataDropdownVisitType" optionLabel="name" optionValue="code" placeholder="Pilih Visit Type" :showClear="true"/>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="branding_type">Branding Type</label>
                    <Dropdown v-model="filters.branding_type" :options="dataDropdownBrandingType" optionLabel="name" optionValue="code" placeholder="Pilih Branding Type" :showClear="true"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>User</label>
                    <Listbox ref="duser" v-model="filters.userid" :options="dataDropdownUserApp" :multiple="true" :filter="true" 
                            optionLabel="label"  listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownUserApp($event, 'filter')">
                        <template #option="slotProps">
                            <div>{{slotProps.option.label}}</div>
                        </template>
                    </Listbox>
                </div>
            </div>
        </div>
         <div class="p-fluid">
            <p><b>User</b> : {{this.convertMultiSelectUserAppString(filters.userid)}}</p><br>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

import moment from 'moment';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownUserApp: false,

            // dataDropdown
            dataDropdownUserApp: null,
            dataDropdownVisitType: [
                {name: 'NEW', code: 'NEW'},
                {name: 'EXISTING', code: 'EXISTING'},
            ],
            dataDropdownBrandingType: [
                {name: 'Pemasangan Pertama', code: 'Pemasangan Pertama'},
                {name: 'Maintenance', code: 'Maintenance'},
            ],

            // filter
            filters: {
                visit_type: null,
                branding_type: null,
                userid: null,
            },
            period: [new Date(), new Date()],
            maxDate: null,
            minDate: null,
        }
    },
    created(){
        this.searchDropdownUserApp('');
    },
    watch: {
        period(){
            //max range 30 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed:{
        user(){ 
            return this.convertMultiSelectUserApp(this.filters.userid); 
        },
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        convertMultiSelectUserApp(data) {
            if(data){
                return data.map((item) => {
                    return item.userid;
                });
            }else{
                return [];
            }
        },
        convertMultiSelectUserAppString(data) {
            if(data){
                return data.map((item) => {
                    return item.label;
                }).join(', ');
            }else{
                return '-';
            }
        },
        // DROPDOWN
        searchDropdownUserApp(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.duser.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownUserApp = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/userapp',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "filter") {
                        this.dataDropdownUserApp = res.data.data;
                        this.loadingDropdownUserApp = false;
                    } else if (purpose == null) {
                        this.dataDropdownUserApp = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            }, 250);
        },
        getDataTable(){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.$emit('submit');
            }else{
                 alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        }
    }
}
</script>

